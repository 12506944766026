import { Redirect, Route, Switch } from "react-router";
import React, { Suspense } from "react";
import Spinner from "../../components/Spinner/Spinner";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";
import PrivateRouteManagerAndOp from "../../components/PrivateRoute/PrivateRouteManagerAndOp";
import PrivateRouteAdmin from "../../components/PrivateRoute/PrivateRouteAdmin";
import AuthorizedRoute from "../../components/PrivateRoute/AuthorizedRoute";
import {
  ROUTE_ERROR,
  ROUTE_LOGIN,
  ROUTE_USERS,
  ROUTE_DASHBOARD,
  MARKETS,
  LOCATIONS,
  ROUTE_ITEMS,
  ROUTE_CATEGORIES,
  ROUTE_MENUS,
  ROUTE_APPS,
  ROUTE_MENU_MARKETS,
  ROUTE_INGREDIENTS,
  ROUTE_RECIPES,
  ROUTE_ROLE,
  ROUTE_DEFAULT_PERMISSIONS,
  ROUTE_CONFIGURATIONS,
  ROUTE_EXCEPTIONS,
  ROUTE_GROUP_EATS,
  ROUTE_QUICK_SIGHT,
  ROUTE_CATERING_SALES_MANAGER,
  ROUTE_CASH_PAR_CURRENCY,
  ROUTE_ADMIN,
  ROUTE_BALANCE_MANAGEMENT,
  ROUTE_NEXT_SUBMISSION_DAY,
  ROUTE_MARKETING_CAMPAIGNS,
  REAL_ESTATE_MAP,
  ROUTE_VIEW_CHANGE_ORDER,
  ROUTE_CHANGE_ORDER_MARKET,
  GOOGLE_FORM_IT_CUSTOMER_SERVICE_SURVEY,
  GOOGLE_FORM_FACILITIES_CUSTOMER_SERVICE_SURVEY,
  ROUTE_QUICKSIGHT_DASHBOARD,
  ROUTE_MODIFIERS,
  ROUTE_MODIFIER_GROUPS,
  ROUTE_MANAGE_ONLINE_HOURS,
  ROUTE_ORDER_THROTTLING,
  ROUTE_MANAGE_DELIVERY_HOURS,
  ENABLING_DISABLING,
  ROUTE_MANAGE_BULK_ONLINE_HOURS,
  ROUTE_MANAGE_BULK_DELIVERY_HOURS,
  ROUTE_MANAGE_DROP_SPOTS,
  ONLINE_ORDER_MENU,
  LOCATION_MENU,
  ROUTE_OPERATOR,
  OPEN_ORDERS,
  ORDER_TRACKING,
} from "../../constants";

const AddMarketingCampaigns = React.lazy(
  () => import("../marketingCamapaigns/AddCampaigns")
);
const EditMarketingCampaigns = React.lazy(
  () => import("../marketingCamapaigns/EditCampaigns")
);
const MarketingCampaigns = React.lazy(
  () => import("../marketingCamapaigns/Campaigns")
);
const NextMondayScreen = React.lazy(
  () => import("../cashPar/nextMonday/nextMonday")
);
const AddBalanceContainer = React.lazy(
  () => import("../cashPar/balanceManagement/AddBalance.jsx")
);
const AdminContainer = React.lazy(() => import("../cashPar/admin/Admin"));
const ViewChangeOrderContainer = React.lazy(
  () => import("../cashPar/viewChangeOrder/ViewChangeOrder")
);
const CurrencyContainer = React.lazy(
  () => import("../cashPar/currencies/Currency")
);
const AddCurrencyContainer = React.lazy(
  () => import("../cashPar/currencies/AddCurrency")
);
const EditCurrencyContainer = React.lazy(
  () => import("../cashPar/currencies/EditCurrency")
);
const ConfigurationsContainer = React.lazy(
  () => import("../configurations/Configurations")
);
const QuickSightDashboardContainer = React.lazy(
  () => import("../quickSight/Dashboard")
);
const QuickSightContainer = React.lazy(
  () => import("../quickSight/QuickSight")
);
const AddQuickSightContainer = React.lazy(
  () => import("../quickSight/AddQuickSight")
);
const EditQuickSightContainer = React.lazy(
  () => import("../quickSight/EditQuickSightUser")
);
const AddConfigurationsContainer = React.lazy(
  () => import("../configurations/AddConfigurations")
);
const EditConfigurationsContainer = React.lazy(
  () => import("../configurations/EditConfigurations")
);
const ExceptionsContainer = React.lazy(
  () => import("../exceptions/Exceptions")
);
const AddExceptionsContainer = React.lazy(
  () => import("../exceptions/AddExceptions")
);
const EditExceptionsContainer = React.lazy(
  () => import("../exceptions/EditExceptions")
);
const GroupEatsContainer = React.lazy(() => import("../groupEats/GroupEats"));
const AddGroupEatsContainer = React.lazy(
  () => import("../groupEats/AddGroupEats")
);
const EditGroupEatsContainer = React.lazy(
  () => import("../groupEats/EditGroupEats")
);
const LoginContainer = React.lazy(() => import("../login/Login"));
const UsersContainer = React.lazy(() => import("../users/Users"));
const AddUsersContainer = React.lazy(() => import("../users/AddUser"));
const EditUsersContainer = React.lazy(() => import("../users/EditUser"));
const DashboardContainer = React.lazy(() => import("../dashboard/Dashboard"));
const ErrorContainer = React.lazy(() => import("../error/Error"));
const MarketsContainer = React.lazy(() => import("../markets/Markets"));
const ChangeOrderMarketContainer = React.lazy(
  () => import("../changeOrderMarkets/Markets")
);
const MenuMarketsContainer = React.lazy(
  () => import("../menuMarkets/MenuMarkets")
);
const AddChangeOrderMarketContainer = React.lazy(
  () => import("../changeOrderMarkets/AddMarket")
);
const EditChangeOrderMarketContainer = React.lazy(
  () => import("../changeOrderMarkets/EditMarket")
);
const AddMarketContainer = React.lazy(() => import("../markets/AddMarket"));
const AddMenuContainer = React.lazy(() => import("../menus/AddMenu"));
const AddCategoryContainer = React.lazy(
  () => import("../categories/AddCategory")
);
const EditMenuContainer = React.lazy(() => import("../menus/EditMenu"));
const EditCategoryContainer = React.lazy(
  () => import("../categories/EditCategory")
);
const AddMenuMarketContainer = React.lazy(
  () => import("../menuMarkets/AddMenuMarket")
);
const EditMarketContainer = React.lazy(() => import("../markets/EditMarket"));
const EditMenuMarketContainer = React.lazy(
  () => import("../menuMarkets/EditMenuMarket")
);
const LocationContainer = React.lazy(() => import("../locations/Locations"));
const ItemContainer = React.lazy(() => import("../items/Items"));
const MenuContainer = React.lazy(() => import("../menus/Menus"));
const CategoryContainer = React.lazy(() => import("../categories/Categories"));
const IngredientContainer = React.lazy(
  () => import("../ingredients/Ingredients")
);
const ModifierContainer = React.lazy(() => import("../Modifiers/Modifiers"));
const AddModifierContainer = React.lazy(
  () => import("../Modifiers/AddModifier")
);
const EditModifierContainer = React.lazy(
  () => import("../Modifiers/EditModifier")
);
const MarkModifier86 = React.lazy(() => import("../Modifiers/MarkModifier86"));
const MassModifier86 = React.lazy(() => import("../Modifiers/MassModifier86"));
const MarkModifierDisable = React.lazy(
  () => import("../Modifiers/MarkDisable")
);
const MassModifierDisable = React.lazy(
  () => import("../Modifiers/MassDisable")
);

const ModifierGroupContiner = React.lazy(
  () => import("../ModifierGroups/ModifierGroups")
);
const AddModifierGroupContainer = React.lazy(
  () => import("../ModifierGroups/AddModifierGroup")
);
const EditModifierGroupContainer = React.lazy(
  () => import("../ModifierGroups/EditModifierGroup")
);

const OperatorContainer = React.lazy(() => import("../Operator/Operator"));
const OrderDetailsContainer = React.lazy(
  () => import("../Operator/OrderDetailsContainer")
);

const AddLocationContainer = React.lazy(
  () => import("../locations/AddLocation")
);
const AddItemContainer = React.lazy(() => import("../items/AddItem"));
const AddIngredientContainer = React.lazy(
  () => import("../ingredients/AddIngredient")
);
const EditLocationContainer = React.lazy(
  () => import("../locations/EditLocation")
);
const LocationOnlineHoursConfig = React.lazy(
  () => import("../locations/LocationHoursConfig")
);
const LocationDropSpotsConfig = React.lazy(
  () => import("../locations/DropSpots")
);
const CreateDropSpotContainer = React.lazy(
  () => import("../locations/AddDropSpot")
);
const EditDropSpotContainer = React.lazy(
  () => import("../locations/EditDropSpot")
);
const EnablingDisabling = React.lazy(
  () => import("../locations/Enabling-Disabling")
);
const MassConfiguration = React.lazy(
  () => import("../locations/MassConfiguration")
);
const EditItemContainer = React.lazy(() => import("../items/EditItem"));
const MassItem86 = React.lazy(() => import("../items/MassItem86"));
const MarkItem86 = React.lazy(() => import("../items/MarkItem86"));
const MarkDisable = React.lazy(() => import("../items/MarkDisable"));
const MassDisable = React.lazy(() => import("../items/MassDisable"));
const EditIngredientContainer = React.lazy(
  () => import("../ingredients/EditIngredient")
);
const EditRoleContainer = React.lazy(() => import("../roles/EditRole"));
const RolesContainer = React.lazy(() => import("../roles/Roles"));
const AddRolesContainer = React.lazy(() => import("../roles/AddRole"));
const AddPermissionsContainer = React.lazy(
  () => import("../permissions/AddPermission")
);
const ChangePasswordContainer = React.lazy(
  () => import("../users/changePassword")
);
const AppsContainer = React.lazy(() => import("../roles/Apps"));

const RecipesContainer = React.lazy(() => import("../recipes/Recipes"));
const AddRecipeContainer = React.lazy(() => import("../recipes/AddRecipe"));
const EditRecipeContainer = React.lazy(() => import("../recipes/EditRecipe"));

const ManagerContainer = React.lazy(
  () => import("../cateringSalesManger/Manager")
);
const AddManagerContainer = React.lazy(
  () => import("../cateringSalesManger/AddManager")
);
const EditManagerContainer = React.lazy(
  () => import("../cateringSalesManger/EditManager")
);
const MapRealEstate = React.lazy(
  () => import("../../components/RealEstateMap/MapRealEstate")
);

//Importing this container for testing purpose
const OrderThrottling = React.lazy(
  () => import("../OrderThrottling/OrderThrottling")
);
const LocationMenuRedisContainer = React.lazy(
  () => import("../locationMenu/locationMenuRedis/locationMenuRedis")
);



const Routes = () => {
  return (
    <Suspense fallback={<Spinner lightBg={true} />}>
      <Switch>
        <PrivateRouteAdmin
          path={`${ROUTE_ADMIN}/:marketId`}
          exact={true}
          component={AdminContainer}
        ></PrivateRouteAdmin>
        <PrivateRoute
          path={ROUTE_VIEW_CHANGE_ORDER}
          exact={true}
          component={ViewChangeOrderContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_OPERATOR}
          exact={true}
          component={OperatorContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_OPERATOR}/:id`}
          exact={true}
          component={OrderDetailsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={GOOGLE_FORM_IT_CUSTOMER_SERVICE_SURVEY}
          exact={true}
          component={ViewChangeOrderContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={GOOGLE_FORM_FACILITIES_CUSTOMER_SERVICE_SURVEY}
          exact={true}
          component={ViewChangeOrderContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_QUICKSIGHT_DASHBOARD}/:id`}
          exact={true}
          component={QuickSightDashboardContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_NEXT_SUBMISSION_DAY}
          exact={true}
          component={NextMondayScreen}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MARKETING_CAMPAIGNS}/edit/:id`}
          exact={true}
          component={EditMarketingCampaigns}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MARKETING_CAMPAIGNS}/create`}
          exact={true}
          component={AddMarketingCampaigns}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_MARKETING_CAMPAIGNS}
          exact={true}
          component={MarketingCampaigns}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_USERS}
          exact={true}
          component={UsersContainer}
        ></PrivateRoute>
        <PrivateRouteAdmin
          path={`${ROUTE_CASH_PAR_CURRENCY}/:marketId`}
          exact={true}
          component={CurrencyContainer}
        ></PrivateRouteAdmin>
        <PrivateRouteAdmin
          path={`${ROUTE_CASH_PAR_CURRENCY}/create/:marketId`}
          exact={true}
          component={AddCurrencyContainer}
        ></PrivateRouteAdmin>
        <PrivateRouteManagerAndOp
          path={`${ROUTE_BALANCE_MANAGEMENT}`}
          exact={true}
          component={AddBalanceContainer}
        ></PrivateRouteManagerAndOp>
        <PrivateRouteAdmin
          path={`${ROUTE_CASH_PAR_CURRENCY}/edit/:id/:marketId`}
          exact={true}
          component={EditCurrencyContainer}
        ></PrivateRouteAdmin>
        <PrivateRoute
          path={ROUTE_QUICK_SIGHT}
          exact={true}
          component={QuickSightContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_QUICK_SIGHT}/create`}
          exact={true}
          component={AddQuickSightContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_QUICK_SIGHT}/edit/:id`}
          exact={true}
          component={EditQuickSightContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_GROUP_EATS}
          exact={true}
          component={GroupEatsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_GROUP_EATS}/create`}
          exact={true}
          component={AddGroupEatsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_GROUP_EATS}/edit/:id`}
          exact={true}
          component={EditGroupEatsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_USERS}/create`}
          exact={true}
          component={AddUsersContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_USERS}/edit/:id`}
          exact={true}
          component={EditUsersContainer}
        ></PrivateRoute>
        <Route
          path={ROUTE_LOGIN}
          exact={true}
          component={LoginContainer}
        ></Route>

        <Route
          path={ROUTE_ERROR}
          exact={true}
          component={ErrorContainer}
        ></Route>
        <PrivateRoute
          path={`${ROUTE_CONFIGURATIONS}`}
          exact={true}
          component={ConfigurationsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_CONFIGURATIONS}/create`}
          exact={true}
          component={AddConfigurationsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_CONFIGURATIONS}/edit/:id`}
          exact={true}
          component={EditConfigurationsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_EXCEPTIONS}`}
          exact={true}
          component={ExceptionsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_EXCEPTIONS}/create`}
          exact={true}
          component={AddExceptionsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_EXCEPTIONS}/edit/:id`}
          exact={true}
          component={EditConfigurationsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_CATERING_SALES_MANAGER}`}
          exact={true}
          component={ManagerContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_CATERING_SALES_MANAGER}/create`}
          exact={true}
          component={AddManagerContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_CATERING_SALES_MANAGER}/edit/:id`}
          exact={true}
          component={EditManagerContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${MARKETS}`}
          exact={true}
          component={MarketsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${MARKETS}/create`}
          exact={true}
          component={AddMarketContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MENUS}/create`}
          exact={true}
          component={AddMenuContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_MENUS}/create`}
          exact={true}
          component={AddMenuContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MENUS}/clone/:id`}
          exact={true}
          component={AddMenuContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_MENUS}/clone/:id`}
          exact={true}
          component={AddMenuContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_CATEGORIES}/create`}
          exact={true}
          component={AddCategoryContainer}
        ></PrivateRoute>
        <PrivateRouteAdmin
          path={`${ROUTE_CHANGE_ORDER_MARKET}`}
          exact={true}
          component={ChangeOrderMarketContainer}
        ></PrivateRouteAdmin>
        <PrivateRouteAdmin
          path={`${ROUTE_CHANGE_ORDER_MARKET}/create`}
          exact={true}
          component={AddChangeOrderMarketContainer}
        ></PrivateRouteAdmin>
        <PrivateRouteAdmin
          path={`${ROUTE_CHANGE_ORDER_MARKET}/edit/:id`}
          exact={true}
          component={EditChangeOrderMarketContainer}
        ></PrivateRouteAdmin>
        <PrivateRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_CATEGORIES}/create`}
          exact={true}
          component={AddCategoryContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${MARKETS}/edit/:id`}
          exact={true}
          component={EditMarketContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_CATEGORIES}/edit/:id`}
          exact={true}
          component={EditCategoryContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_CATEGORIES}/edit/:id`}
          exact={true}
          component={EditCategoryContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_CATEGORIES}/clone/:id`}
          exact={true}
          component={AddCategoryContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_CATEGORIES}/clone/:id`}
          exact={true}
          component={AddCategoryContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MENUS}/edit/:id`}
          exact={true}
          component={EditMenuContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_MENUS}/edit/:id`}
          exact={true}
          component={EditMenuContainer}
        ></PrivateRoute>
        <PrivateRoute
          path="/"
          exact={true}
          component={DashboardContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_DASHBOARD}
          exact={true}
          component={DashboardContainer}
        ></PrivateRoute>
        <AuthorizedRoute
          path={`${LOCATIONS}`}
          exact={true}
          component={LocationContainer}
          parentModule="Locations"
          childModule="view location"
        ></AuthorizedRoute>
        <PrivateRoute
          path={`${ROUTE_MENUS}`}
          exact={true}
          component={MenuContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_MENUS}`}
          exact={true}
          component={MenuContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ONLINE_ORDER_MENU}`}
          exact={true}
          component={() => <Redirect to={ROUTE_MODIFIERS} />}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_RECIPES}`}
          exact={true}
          component={RecipesContainer}
        ></PrivateRoute>
        <AuthorizedRoute
          path={`${ROUTE_ITEMS}`}
          exact={true}
          component={ItemContainer}
          parentModule="Menu"
          childModule="view item"
        ></AuthorizedRoute>
        <PrivateRoute
          path={`${ROUTE_CATEGORIES}`}
          exact={true}
          component={CategoryContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_CATEGORIES}`}
          exact={true}
          component={CategoryContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_INGREDIENTS}`}
          exact={true}
          component={IngredientContainer}
        ></PrivateRoute>
        <AuthorizedRoute
          path={`${ROUTE_MODIFIERS}`}
          exact={true}
          component={ModifierContainer}
          parentModule="Menu"
          childModule="view modifier"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ROUTE_MODIFIERS}/create`}
          exact={true}
          component={AddModifierContainer}
          parentModule="Menu"
          childModule="create modifier"
        ></AuthorizedRoute>
        <PrivateRoute
          path={`${ROUTE_MODIFIERS}/edit/:id`}
          exact={true}
          component={EditModifierContainer}
        ></PrivateRoute>
        <AuthorizedRoute
          path={`${ROUTE_MODIFIERS}/mark86/:id`}
          exact={true}
          component={MarkModifier86}
          parentModule="Menu"
          childModule="86 modifier"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ROUTE_MODIFIERS}/mark86`}
          exact={true}
          component={MassModifier86}
          parentModule="Menu"
          childModule="mass 86 modifier"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ROUTE_MODIFIERS}/disable/:id`}
          exact={true}
          component={MarkModifierDisable}
          parentModule="Menu"
          childModule="disable modifier"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ROUTE_MODIFIERS}/disable`}
          exact={true}
          component={MassModifierDisable}
          parentModule="Menu"
          childModule="disable modifier"
        ></AuthorizedRoute>
        <PrivateRoute
          path={`${ROUTE_MODIFIERS}/clone/:id`}
          exact={true}
          component={AddModifierContainer}
        ></PrivateRoute>
        {/* <PrivateRoute
          path={`${ROUTE_MODIFIER_GROUPS}`}
          exact={true}
          component={ModifierGroupContiner}
        ></PrivateRoute> */}
        <PrivateRoute
          path={`${ROUTE_MODIFIER_GROUPS}/create`}
          exact={true}
          component={AddModifierGroupContainer}
        ></PrivateRoute>
        {/* <PrivateRoute
          path={`${ROUTE_MODIFIER_GROUPS}/edit/:id`}
          exact={true}
          component={EditModifierGroupContainer}
        ></PrivateRoute> */}
        <PrivateRoute
          path={`${ROUTE_MODIFIER_GROUPS}/create`}
          exact={true}
          component={AddModifierGroupContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MODIFIER_GROUPS}/edit/:id`}
          exact={true}
          component={EditModifierGroupContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MODIFIER_GROUPS}`}
          exact={true}
          component={ModifierGroupContiner}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MODIFIER_GROUPS}/create`}
          exact={true}
          component={AddModifierGroupContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MODIFIER_GROUPS}/edit/:id`}
          exact={true}
          component={EditModifierGroupContainer}
        ></PrivateRoute>
        <AuthorizedRoute
          path={`${LOCATIONS}/create`}
          exact={true}
          component={AddLocationContainer}
          parentModule="Locations"
          childModule="create location"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${LOCATIONS}/edit/:id${ROUTE_MANAGE_ONLINE_HOURS}`}
          exact={true}
          component={LocationOnlineHoursConfig}
          parentModule="Locations"
          childModule="view location_hours"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${LOCATIONS}/edit/:id${ENABLING_DISABLING}`}
          exact={true}
          component={EnablingDisabling}
          parentModule="Locations"
          childModule="update location status"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${LOCATIONS}/edit/:id${ROUTE_MANAGE_DELIVERY_HOURS}`}
          exact={true}
          component={LocationOnlineHoursConfig}
          parentModule="Locations"
          childModule="view location_hours"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${LOCATIONS}/edit/:id${ROUTE_MANAGE_DROP_SPOTS}`}
          exact={true}
          component={LocationDropSpotsConfig}
          parentModule="Locations"
          childModule="view drop spot"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${LOCATIONS}/edit/:id${ROUTE_MANAGE_DROP_SPOTS}/create`}
          exact={true}
          component={CreateDropSpotContainer}
          parentModule="Locations"
          childModule="create drop spot"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${LOCATIONS}/edit/:id${ROUTE_MANAGE_DROP_SPOTS}/edit/:dropSpotId`}
          exact={true}
          component={EditDropSpotContainer}
          parentModule="Locations"
          childModule="update drop spot"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${LOCATIONS}${ROUTE_MANAGE_BULK_ONLINE_HOURS}`}
          exact={true}
          component={MassConfiguration}
          parentModule={"Locations"}
          childModule="view groups"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${LOCATIONS}${ROUTE_MANAGE_BULK_DELIVERY_HOURS}`}
          exact={true}
          component={MassConfiguration}
          parentModule={"Locations"}
          childModule="view groups"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ROUTE_ITEMS}/create`}
          exact={true}
          component={AddItemContainer}
          parentModule="Menu"
          childModule="create item"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_ITEMS}/create`}
          exact={true}
          component={AddItemContainer}
          parentModule="Menu"
          childModule="create item"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_ITEMS}`}
          exact={true}
          component={ItemContainer}
          parentModule="Menu"
          childModule="view item"
        ></AuthorizedRoute>
        <PrivateRoute
          path={`${ROUTE_ITEMS}/clone/:id`}
          exact={true}
          component={AddItemContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_ITEMS}/edit/:id`}
          exact={true}
          component={EditItemContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_ITEMS}/clone/:id`}
          exact={true}
          component={AddItemContainer}
        ></PrivateRoute>
        <AuthorizedRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_ITEMS}/mark86/:id`}
          exact={true}
          component={MarkItem86}
          parentModule="Menu"
          childModule="86 item"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_ITEMS}/disable/:id`}
          exact={true}
          component={MarkDisable}
          parentModule="Menu"
          childModule="disable item"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_ITEMS}/disable`}
          exact={true}
          component={MassDisable}
          parentModule="Menu"
          childModule="disable item"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ONLINE_ORDER_MENU}${ROUTE_ITEMS}/mark86`}
          exact={true}
          component={MassItem86}
          parentModule="Menu"
          childModule="mass 86 item"
        ></AuthorizedRoute>
        <PrivateRoute
          path={`${ROUTE_INGREDIENTS}/create`}
          exact={true}
          component={AddIngredientContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_INGREDIENTS}/clone/:id`}
          exact={true}
          component={AddIngredientContainer}
        ></PrivateRoute>
        <AuthorizedRoute
          path={`${LOCATIONS}/edit/:id`}
          exact={true}
          component={EditLocationContainer}
          parentModule={"Locations"}
          childModule="update location"
        ></AuthorizedRoute>
        <PrivateRoute
          path={`${ROUTE_INGREDIENTS}/edit/:id`}
          exact={true}
          component={EditIngredientContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_RECIPES}/edit/:id`}
          exact={true}
          component={EditRecipeContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_ITEMS}/edit/:id`}
          exact={true}
          component={EditItemContainer}
        ></PrivateRoute>
        <AuthorizedRoute
          path={`${ROUTE_ITEMS}/mark86/:id`}
          exact={true}
          component={MarkItem86}
          parentModule="Menu"
          childModule="86 item"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ROUTE_ITEMS}/disable/:id`}
          exact={true}
          component={MarkDisable}
          parentModule="Menu"
          childModule="disable item"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ROUTE_ITEMS}/disable`}
          exact={true}
          component={MassDisable}
          parentModule="Menu"
          childModule="disable item"
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${ROUTE_ITEMS}/mark86`}
          exact={true}
          component={MassItem86}
          parentModule="Menu"
          childModule="mass 86 item"
        ></AuthorizedRoute>
        <PrivateRoute
          path={`${ROUTE_ROLE}`}
          exact={true}
          component={RolesContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_ROLE}/create`}
          exact={true}
          component={AddRolesContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_ROLE}/clone/:id`}
          exact={true}
          component={AddRolesContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_ROLE}/edit/:id`}
          exact={true}
          component={EditRoleContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_ROLE}/apps/:id`}
          exact={true}
          component={AppsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_DEFAULT_PERMISSIONS}`}
          exact={true}
          component={AddPermissionsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_USERS}/changePassword/:id`}
          exact={true}
          component={ChangePasswordContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MENU_MARKETS}`}
          exact={true}
          component={MenuMarketsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MENU_MARKETS}/create`}
          exact={true}
          component={AddMenuMarketContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MENU_MARKETS}/clone/:id`}
          exact={true}
          component={AddMenuMarketContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_RECIPES}/create`}
          exact={true}
          component={AddRecipeContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_RECIPES}/clone/:id`}
          exact={true}
          component={AddRecipeContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${ROUTE_MENU_MARKETS}/edit/:id`}
          exact={true}
          component={EditMenuMarketContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={`${REAL_ESTATE_MAP}/:stateParam`}
          exact={true}
          component={MapRealEstate}
        ></PrivateRoute>
        <PrivateRoute
          path={`${REAL_ESTATE_MAP}`}
          exact={true}
          component={() => <Redirect to={`${REAL_ESTATE_MAP}/AZ`} />}
        ></PrivateRoute>
        <AuthorizedRoute
          path={`${LOCATIONS}/edit/:id${ROUTE_ORDER_THROTTLING}`}
          exact={true}
          component={OrderThrottling}
          parentModule={"Locations"}
          childModule={"view throttling"}
        ></AuthorizedRoute>
        <AuthorizedRoute
          path={`${MARKETS}/edit/:id${ROUTE_ORDER_THROTTLING}`}
          exact={true}
          component={OrderThrottling}
          parentModule={"Locations"}
          childModule={"view throttling"}
        ></AuthorizedRoute>
        <PrivateRoute
          path={`${LOCATION_MENU}`}
          exact={true}
          component={LocationMenuRedisContainer}
        ></PrivateRoute>
        <Route exact={true} path={ROUTE_APPS} component={AppsContainer}></Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
