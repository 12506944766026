import React, { useState } from "react";
import { AuthContext, AuthProvider } from "./AuthContext";
import { withRouter, RouteComponentProps } from "react-router";
import { AuthStateInterface } from "./AuthInterface";
import {
    TOKEN,
    NAME,
    ROLE,
    ID,
    ROLE_ID,
    USER_ID,
    USER_ID_ORIGINAL,
    ORIGINAL_ROLE
} from "../../constants";
import axios from "../../axios/axios";
import { getCookie } from "../../utils";
import { useDispatch } from "react-redux";
import { setBorderRedFalse } from "../../redux/actions/redBorderAction";
import { setLoadWidjetFalse } from "../../redux/actions/loadWidjetsOnMasqueradingAction";

const AuthContextContainer: React.FunctionComponent<RouteComponentProps> = ({
  history,
  children,
}) => {
  const defaultAuthState: AuthStateInterface = {
    isAuthenticated: false,
    loadWidjetsOnLogin: false,
    token: "",
    isBorderRed: false,
    loadWidjets: false,
  };
  const dispatch = useDispatch();

  const [state, setState] = useState<AuthStateInterface>(defaultAuthState);
  const setLoadWidjetsOnLogIn: (loadWidjetsOnLogin: boolean) => void = (
    loadWidjetsOnLogin: boolean
  ): void => {
    setState((prev) => {
      return {
        ...prev,
        loadWidjetsOnLogin: loadWidjetsOnLogin,
      };
    });
  };
  const setBorderRed: (Border: boolean) => void = (Border: boolean): void => {
    setState((prev) => {
      return {
        ...prev,
        isBorderRed: Border,
      };
    });
  };

  const authenticateUser: (token: string) => void = (token: string): void => {
    localStorage.setItem(TOKEN, token);
    setState((prev) => {
      return {
        ...prev,
        isAuthenticated: true,
        token: token,
      };
    });
  };

  const loginUser: (
    name: string,
    role: string,
    id: string,
    role_id: string,
    options: any,
    user_id : string
  ) => void = (
    name: string,
    role: string,
    id: string,
    role_id: string,
    options: any,
    user_id : string
  ): void => {
    localStorage.setItem(USER_ID,user_id)
    localStorage.setItem(ID, id);
    localStorage.setItem(NAME, name);
    localStorage.setItem(ROLE, role);
    localStorage.setItem(ORIGINAL_ROLE, role);
    localStorage.setItem(ROLE_ID, role_id);
    localStorage.setItem('SETUP_TIME', String(new Date().getTime()) )
    setState((prev) => {
      return {
        ...prev,
        name: name,
        role: role,
      };
    });
  };

  const logoutMasqueradedUser: (callback:any) => void = (callback:any): void => {
    callback("")
    const original_user_id = localStorage.getItem(USER_ID_ORIGINAL) || ''
    localStorage.removeItem(USER_ID_ORIGINAL)
    localStorage.setItem(USER_ID,original_user_id)
    if (localStorage.getItem(ORIGINAL_ROLE)) {
        let temp = localStorage.getItem(ORIGINAL_ROLE)
        // @ts-ignore: Unreachable code error
        localStorage.setItem(ROLE, temp)
    }
    let original_token: any = getCookie("__token__");
    let suidold: any = getCookie("__suidold__");
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN"),
    };
    axios.delete("logout").then((res) => {
      localStorage.setItem(TOKEN, original_token);
      dispatch(setLoadWidjetFalse());

      dispatch(setBorderRedFalse());
      history.push("/");
    });
    let DOMAIN: any;
    if (typeof process.env.REACT_APP_DOMAIN !== "undefined") {
      DOMAIN = process.env.REACT_APP_DOMAIN;
    } else {
      DOMAIN = ".zupas.com";
    }

    document.cookie = `__suid__= ${suidold} ; Max-Age=86400; domain=${DOMAIN}; `;
    document.cookie = `__guid__= ${suidold} ; Max-Age=86400; domain=${DOMAIN}; `;
    document.cookie = `__tokenmask__=; Max-Age=0;  domain=${DOMAIN};`;

    document.cookie = `__suidold__=; Max-Age=0;domain = ${DOMAIN};`;

  };

  const logoutUser: () => void = (): void => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN"),
    };
    axios
      .delete("logout", {
        headers: headers,
      })
      .then((res) => {})
      .catch((err) => {});

    let DOMAIN: any;
    if (typeof process.env.REACT_APP_DOMAIN !== "undefined") {
      DOMAIN = process.env.REACT_APP_DOMAIN;
    } else {
      DOMAIN = ".zupas.com";
    }
    localStorage.removeItem(USER_ID)
    localStorage.removeItem("state")
    localStorage.removeItem(TOKEN);
    localStorage.removeItem("AUTH_NAME");
    localStorage.removeItem("AUTH_ROLE");
    localStorage.removeItem("AUTH_ID");
    localStorage.removeItem(ORIGINAL_ROLE);
    localStorage.removeItem(USER_ID_ORIGINAL);
    localStorage.removeItem(ROLE_ID);
    document.cookie.split(";").forEach(function(c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;Max-Age=0;domain=" + DOMAIN + ";");
    });
    setState((prev) => {
      return {
        ...prev,
        isAuthenticated: false,
      };
    });
  };

  const checkAuthentication: () => boolean = (): boolean => {
    return !!localStorage.getItem(TOKEN);
  };

  const checkAuthenticationManagerOrOP: () => boolean = (): boolean => {
    // Converting role to lower case to avoid issue if manager and operating partners name comes in uppercase 
    if (localStorage.getItem(ROLE)?.toLocaleLowerCase() === "manager" || localStorage.getItem(ROLE)?.toLocaleLowerCase() === "operating partners") {
        return true
    } else {
        return false
    }


  };

  const checkAuthenticationAdmin: () => boolean = (): boolean => {
    // Converting role to lower case to avoid issue if admin name comes in uppercase 
    if (localStorage.getItem(ROLE)?.toLocaleLowerCase() === "admin"  )

      return true

    else

      return false

  };

  return (
    <AuthProvider
      value={{
        checkAuthenticationAdmin,
        checkAuthenticationManagerOrOP,
        loginUser,
        isAuthenticated: state.isAuthenticated,
        authenticateUser,
        logoutUser,
        checkAuthentication,
        logoutMasqueradedUser,
        setBorderRed,
        setLoadWidjetsOnLogIn,
        loadWidjetsOnLogin: state.loadWidjetsOnLogin,
        loadWidjets: state.loadWidjets,
        isBorderRed: state.isBorderRed,
      }}
    >
      {children}
    </AuthProvider>
  );
};

export default withRouter(AuthContextContainer);
