// Application Constants
import { useParams } from "react-router";
import { Days } from "./models/location.model";

export const TOKEN = "AUTH_TOKEN";
export const NAME = "AUTH_NAME";
export const ROLE = "AUTH_ROLE";
export const ID = "AUTH_ID";
export const AUTH_TOKEN_HEADER = "Authorization";
export const COOKIE_HEADER = "Legacy";
export const ROLE_ID = "AUTH_ROLE_ID";
export const USER_ID = "USER_ID";
export const USER_ID_ORIGINAL = "USER_ID_ORIGINAL";
export const ORIGINAL_ROLE = "ORIGINAL_ROLE";
// API Contants
export const CATERING_MANAGERS = "catering_managers";
export const CURRENCY_SUBMISSIONS = "/currencySubmissions";
export const GROUPEATS = "groupEats";
export const CONFIGURATIONS = "/configurations";
export const LOGIN = "/token";
export const USERS = "/users";
export const PRODUCTS = "products";
export const MARKETS = "/markets";
export const EXCEPTIONS = "/exceptions";
export const MENUS = "/menus";
export const ITEMS = "/items";
export const MODIFIERS = "/modifiers";
export const MODIFIER_GROUPS = "/modifierGroups";
export const INGREDIENTS = "/ingredients";
export const CATEGORIES = "/categories";
export const OPEN_ORDERS = "/open-orders";
export const ORDER_TRACKING = "/order-tracking";
export const MENU_MARKETS = "/menu_markets";
export const ONLINE_ORDER_MENU = "/online_order_menu";
export const MENU_MARKETS_CLONE = "/menu_markets_clone";
export const LOCATIONS = "/locations";
export const ONLINE_HOURS = "/online-hours";
export const OVERRIDE_HOURS = "/override-hours";
export const OVERRIDE_DELIVERY_HOURS = "/override-delivery-hours";
export const DELIVERY_HOURS = "/delivery-hours";
export const UPDATE_STATUS = "/updateStatus";
export const HOURS = "/hours";
export const TIME_SLOTS = "/timeSlots";
export const LOCATION_HOURS = "/location-hours";
export const RECIPES = "/recipes";
export const ROLES = "/roles";
export const WIDGETS = "/widgets";
export const MASQUERADE = "/masquerade/token";
export const USER_DASHBOARD = "/users/dashboard";
export const CURRENCY_MANAGERS = "/currencyManagers";
export const MARKETING_CAMPAIGNS = "/marketingCampaigns";
export const MY_PERMISSIONS = "/myPermissions";
export const BRINK_ITEMS = "/brinkItems";
export const BRINK_LOCATIONS = "/brinkLocations";
export const GEO_LOCATIONS = "/geoLocations";
export const SERVICES = "/services";
export const CATERING_DELIVERY_THROTTLING = "/catering-delivery-throttling";
export const THROTTLING_TIERS = "/locations/throttling-tiers";
export const DROP_SPOTS = "/dropSpots";
export const BRINK_DESTINATIONS = "/brinkDestinations";
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// Routes Constants
export const ROUTE_MARKETING_CAMPAIGNS = "/marketing-campaigns";
export const ROUTE_CATERING_SALES_MANAGER = "/catering-sales-manager";
export const ROUTE_NEXT_SUBMISSION_DAY = "/nextSubmissionDay";
export const ROUTE_BALANCE_MANAGEMENT = "/balance-management";
export const ROUTE_ADMIN = "/cash-par-admin";
export const ROUTE_CASH_PAR_CURRENCY = "/cash-par-currency";
export const ROUTE_VIEW_CHANGE_ORDER = "/view-change-order";
export const ROUTE_QUICK_SIGHT = "/quick-sight";
export const ROUTE_GROUP_EATS = "/group-eats";
export const ROUTE_ERROR = "/error";
export const ROUTE_LOGIN = "/login";
export const ROUTE_USERS = "/users";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_MARKETS = "/markets";
export const ROUTE_CHANGE_ORDER_MARKET = "/change-order-markets";
export const ROUTE_CONFIGURATIONS = "/configurations";
export const ROUTE_EXCEPTIONS = "/exceptions";
export const ROUTE_MENUS = "/menus";
export const ROUTE_ITEMS = "/items";
export const ROUTE_CATEGORIES = "/categories";
export const ROUTE_INGREDIENTS = "/ingredients";
export const ROUTE_RECIPES = "/recipes";
export const ROUTE_MENU_MARKETS = "/menu-markets";
export const ROUTE_LOCATIONS = "/locations";
export const ROUTE_MANAGE_ONLINE_HOURS = "/manage-online-hours";
export const ROUTE_MANAGE_DELIVERY_HOURS = "/manage-delivery-hours";
export const ROUTE_MANAGE_BULK_ONLINE_HOURS = "/manage-bulk-online-hours";
export const ROUTE_MANAGE_BULK_DELIVERY_HOURS = "/manage-bulk-delivery-hours";
export const ROUTE_MANAGE_DROP_SPOTS = "/manage-drop-spots";
export const ROUTE_APPS = "/apps";
export const ROUTE_HASH = "#";
export const ROUTE_PREP = "http://preparation.zupas.com/";
export const ROUTE_SYSTEMS = "http://systems.zupas.com/";
export const ROUTE_SCHEDULER = "http://scheduler.zupas.com/";
export const ROUTE_INVENTORY = "http://inventory.zupas.com/";
export const ROUTE_MANAGE = "http://manage.zupas.com/users";
export const ROUTE_APPS_API = "/roles/apps/";
export const ROUTE_ROLE = "/users/roles";
export const ROUTE_DEFAULT_PERMISSIONS = "/users/default/permission";
export const ROUTE_QUICKSIGHT_DASHBOARD = "/quicksight-dashboard";
export const ROUTE_MODIFIERS = "/modifiers";
export const ROUTE_MODIFIER_GROUPS = "/modifier-groups";
export const BRINK_MODIFIER_GROUPS = "/brinkModifierGroups";
export const BRINK_MODIFIERS = "/brinkModifiers";
export const ROUTE_ORDER_THROTTLING = "/order-throttling";
export const ENABLING_DISABLING = "/enabling-disabling";
export const LOCATION_MENU = "/location-menu";
export const ROUTE_OPERATOR = "/operator";
export const ROUTE_ORDERS = "/orders";


//Operator Order Status
export const CURBSIDE = "Curbside";
export const CURBSIDE_DESTINATION_ID = 25;
export const GOOD_LIFE_LANE = "Good Life Lane";
export const GOOD_LIFE_LANE_DESTINATION_ID = 26;
export const IN_STORE = "In-Store";
export const IN_STORE_DESTINATION_ID = 12;
export const DELIVERY = "Delivery";
export const CANCELLED = "Cancelled";
export const FAILED = "Failed";
export const DELIVERY_DESTINATION_ID = 15;
export const DELIVERED = "delivered";
export const SCHEDULED = "scheduled";
export const ASSIGNED = "assigned";
export const PICKUP = "picked_up";
export const DASHERCANCELLED = "cancelled";
export const UNASSIGNED = "unassigned";
export const ENROUTE_TO_DROPOFF = "enroute_to_dropoff";
export const DASHER_PICKUP = "enroute_to_pickup";
export const ARRIVE_AT_CUSTOMERS = "arrived_at_consumer";
export const NO = "No";
export const ADD = 'Add';
export const SUB = 'Sub';


export const PROCESSING = "Processing";
export const ACTIVE = "Active";
export const PREPARED = "Prepared";
export const ASSEMBLED = "Assembled";
export const COMPLETE = "Complete";
export const QUEUE_FOR_ORDER_STATUS = "orderStatusQueue";
export const QUEUE_FOR_DOOR_DASH = "doorDashQueue";

export const DELIVERY_ORDER_TYPE = "DELIVERY_ORDER";
export const ASAP_TYPE = "ASAP";
export const NO_TIME_SLOTS_PRESENT = "00:00:00";
export const PICK_UP_METHOD_IN_STORE = "IN_STORE";
export const PICK_UP_METHOD_CURBSIDE = "CURBSIDE";
export const SELECTED_STORE = "SelectedLocation";

export const ORDER_MICRO_SERIVCE = process.env.REACT_APP_ORDER_MICRO_SERIVCE;
//Login API Contants

export const GRANT_TYPE_VALUE = "password";
export const CLIENT_ID_VALUE = 2;
export const CLIENT_SECRET_VALUE = "sNTTCaVvYSyxR6BWce9WZfupl5TjWwEGpA103oll";
export const SCOPE = "*";
export const API_GATEWAY_URL =
  "https://aldz2g2s8c.execute-api.us-west-2.amazonaws.com/Prod/getembedurl";

//Real State Map Contants
export const REAL_ESTATE_MAP_LOCATIONS = "realstate/realestatemap";
export const REAL_ESTATE_MAP = "/PropertyTracker";
export const TRAVEL_TIME_URL = "https://api.traveltimeapp.com/v4/time-map";
export const TRAVEL_TIME_APPLICATION_ID = "19e0c3eb";
export const TRAVEL_TIME_KEY = "5440725d38244530c93357583251a910";
export const GOOGLE_API_KEY = "AIzaSyDi6mEC315xQerpS4DBovi_UY0EbuTkN6U";

// Real Estate File uploading
export const REAL_MAP_FILE_UPLOAD = "/uploadFiles";
export const REAL_MAP_FETCH_FILE = "/fetchFiles";
export const REAL_MAP_DOWNLOAD_FILE = "/downloadFile";
export const REAL_MAP_DELETE_FILE = "/deleteFile";
export const REAL_MAP_COMPETITION_FILTER = "realstate/competitionFilter";
export const REAL_MAP_TRAVEL_TIME = "realstate/travelTime";
export const REAL_MAP_SYNC_DATA = "/realstate/refreshMap";

// Google Forms
export const GOOGLE_FORM_IT_CUSTOMER_SERVICE_SURVEY =
  "https://docs.google.com/forms/d/e/1FAIpQLSdcq2ZR17MB74fsmZr0bVlDMN2h_DO7WZ-nYiZrzIIrSCxtOA/viewform";
export const GOOGLE_FORM_FACILITIES_CUSTOMER_SERVICE_SURVEY =
  "https://docs.google.com/forms/d/e/1FAIpQLSd6qoJDUvJ4-9TZKGcLrtaa9jh1DoaHViBzDhNLX2wScQbnlg/viewform";

//Half limit available for these categories
export const categoriesName = [
  "Soups",
  "Salads",
  "Protein Bowls",
  "Bowls",
  "Drinks",
];

export const maskPattern = /(\d{3})(\d{3})(\d{4})/;

 // Exclude Filter from following Modules
 export const NO_FILTER_MODULES = [
   "Menus",
   "Categories",
   "Group Eats",
   "Configurations",
   "QuickSight Users",
   "Catering Sales Managers",
   "Currencies",
   "Admin",
   "Campaigns",
   "Modifiers",
   "Modifier Groups",
   "Disabling",
   "Form Submission",
 ];

 export const OrderStatus = ["all", "processed", "failed", "cancelled", "submitted"];

 export const DasherStatus = ["all", "delivered", "scheduled", "assigned"];
 export const QSRStatus = [
   "all",
   "active",
   "prepared",
   "assembled",
   "complete",
 ];

 export const OrderTypes = [
   {
     name: "All",
     id: undefined,
   },
   {
     name: "Online Takeout",
     id: 12,
   },
   {
     name: "CZ Delivery",
     id: 15,
   },
   {
     name: "Good Life Lane",
     id: 26,
   },
   {
     name: "Curbside",
     id: 25,
   },
 ];
      export const OperatorFilters = [
        "id",
        "brink_order_id",
        "customer.first_name",
        "customer.last_name",
        "location_id",
        "destination_id",
        "fire_time",
        "customer.email",
        "customer.phone",
        "status",
        "total_price",
      ];




 //Reasons Array
 export const EIGHTYSIXING_REASONS = [
   { reason: "Out Of Item/Ingredient" },
   { reason: "Out But Prepping Item/Ingredient" },
   { reason: "Unable To Prep Item/Ingredient" },
 ];
 export const DISABLING_ITEM_REASONS = [
   { reason: "Seasonal Item Ended" },
   { reason: "Supplier Out Of Item/Ingredient" },
   { reason: "Broken Prepping Equipment" },
 ];
 export const DISABLING_STORE_REASONS = [
   { reason: "Understaffed" },
   { reason: "Can't Keep Up With Order Volume" },
   { reason: "Kitchen System Issues/Offline" },
   { reason: "Facilities Issues" },
   { reason: "IT Issues" },
   { reason: "Utilities Outage" },
 ];
 export const OVERRIDE_HOURS_REASONS = [
   { reason: "Understaffed" },
   { reason: "Store Party" },
   { reason: "Facilities Issues" },
   { reason: "IT Issues" },
   { reason: "Utilities Outage" },
 ];

 export const bussinessDayEnd = new Date(new Date().setHours(23, 59, 0, 0)); //To do: Update according to business hours

 export const days: Days[] = [
   { id: 1, name: "Monday" },
   { id: 2, name: "Tuesday" },
   { id: 3, name: "Wednesday" },
   { id: 4, name: "Thursday" },
   { id: 5, name: "Friday" },
   { id: 6, name: "Saturday" },
   { id: 7, name: "Sunday" },
 ];

 export const REGEX = {
   userName: /^(?!\.)(?!.*\.$)(?!.*\.\.)[a-zA-Z0-9.]+$/,
 };

